import React from "react"
import Layout from "../../components/layout"
import { graphql } from "gatsby"
import ContentSectionMarkdown from "../../components/homepage/ContentSection-md"

const RecipesPage = props => {
  //
  // const { Trending } = useSelector(state => ({
  //   ...state.mainReducer.data,
  // }))
  //TODO move text to grapql query
  let text =
    "Obsessed with Italian Food and Wine? we can help with that! miaggrego is\n" +
    "        offering up the tastiest dishes, pairing each one with our favorite\n" +
    "        wines, hand-picked by Italian wine experts who really understand how\n" +
    "        specific foods mix with wine on the pallet; we guarantee this is an\n" +
    "        original experience you will come back to again and again."
  return (
    <Layout>
      <hr style={{ background: "white" }} />
      <ContentSectionMarkdown
        title="RECIPES"
        text={text}
        align="center"
        data={props.data.recipes_}
        lg={3}
        xs={12}
        sm={4}
        height="180px"
        style={{ image: "cover", height: "180px" }}
      />
    </Layout>
  )
}
export default RecipesPage

// get data from redux store or from graphql page query like below
export const pageQuery = graphql`
  {
    recipes_: allMarkdownRemark(
      filter: { frontmatter: { layout: { eq: "recipe" } } }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            subtitle
            date
            content
            preparationTime
            serving
            category
            region
            tags
            suggested_articles
            suggested_recipes
            suggested_wines
            featuredImage {
              childImageSharp {
                fixed {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
  }
`
